import { MantineColor } from '@mantine/core';
import { z } from 'zod';

// Define sub-schema for the last_exam, last_heartworm_lab, and last_bloodwork_lab objects
export const LabSchema = z.object({
    date: z.string(), // ex: "2023-10-10 00:00:00" - always 00:00:00 for now
    products: z.array(z.string()),
});

// Define sub-schema for instructions
export const InstructionsSchema = z.object({
    default: z.string(),
    options: z.array(z.string()),
});

export const CompoundReasonSchema = z.object({
    code: z.string(),
    displayName: z.string(),
});
export type CompoundReason = z.TypeOf<typeof CompoundReasonSchema>;
export const CompoundReasonsSchema = z.object({
    default: z.string(),
    options: z.array(CompoundReasonSchema),
});

// All possible statuses
export enum PRESCRIPTION_STATUS {
    AWAITING_CLIENT = 'AWAITING_CLIENT',
    CANCELLED = 'CANCELLED',
    CONVERTED = 'CONVERTED',
    NOT_CONVERTED = 'NOT_CONVERTED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    TIMED_OUT = 'TIMED_OUT',
    UNKNOWN_PATIENT = 'UNKNOWN_PATIENT',

    // These are fake statuses that we derive on the frontend
    // At some point in the future, we may do some refactoring to support these on the backend fully
    DISCARDED = 'DISCARDED',
    DUPLICATE = 'DUPLICATE',
    PREPARING = 'PREPARING',
    VETSOURCE = 'VETSOURCE',
}

export enum DOCTOR_STATUS {
    APPROVED = 'APPROVED',
    NO_ACTION_NEEDED = 'NO_ACTION_NEEDED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
}

export enum DOCUMENT_SOURCE {
    EMAIL = 'EMAIL',
    FAX = 'FAX',
    PROACTIVE = 'PROACTIVE',
    VETSOURCE = 'VETSOURCE',
}

export enum PRESCRIPTION_OR_DOCTOR_STATUS {
    APPROVED = 'APPROVED',
    AWAITING_CLIENT = 'AWAITING_CLIENT',
    CANCELLED = 'CANCELLED',
    CONVERTED = 'CONVERTED',
    NO_ACTION_NEEDED = 'NO_ACTION_NEEDED',
    NOT_CONVERTED = 'NOT_CONVERTED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    TIMED_OUT = 'TIMED_OUT',
    UNKNOWN_PATIENT = 'UNKNOWN_PATIENT',

    // These are fake statuses that we derive on the frontend
    // At some point in the future, we may do some refactoring to support these on the backend fully
    DISCARDED = 'DISCARDED',
    DUPLICATE = 'DUPLICATE',
    PREPARING = 'PREPARING',
    VETSOURCE = 'VETSOURCE',
}

// Optional fields may be returned from some endpoints but not others
export const PrescriptionSchema = z.object({
    approval_data: z
        .object({
            instructions: z.string(),
            refills: z.string(),
            expiration: z.string().nullable(),
            is_compound: z.boolean(),
            compound_instructions: z.string().nullable(),
            signed_at: z.string(),
            signature_printed_name: z.string().nullable(),
        })
        .nullable()
        .optional(),
    approved_pdf_url: z.string().nullable().optional(),
    compound_reasons: CompoundReasonsSchema.nullable().optional(),
    client_name: z.string().nullable(),
    doctor_status: z.nativeEnum(DOCTOR_STATUS),
    document_source: z.nativeEnum(DOCUMENT_SOURCE).optional(),
    duplicate_of: z.string().nullable().optional(),
    email: z.string().nullable(),
    instructions: InstructionsSchema.nullable().optional(),
    is_compound: z.boolean().optional(),
    last_bloodwork_lab: LabSchema.nullable(),
    last_exam: LabSchema.nullable(),
    last_heartworm_lab: LabSchema.nullable(),
    last_seen_dvm: z.string().nullable().optional(),
    note_count: z.number(),
    // TODO: eventually refactor these types since different endpoints return different fields
    // TODO: for example, /inbox returns patient_key, but /list-prescriptions does not
    patient_key: z.string().nullable().optional(),
    pet_name: z.string().nullable(),
    pet_sex: z.string().nullable().optional(),
    pet_species: z.string().nullable().optional(),
    pet_weight: z.number().nullable().optional(),
    pet_weight_unit: z.string().nullable().optional(),
    pet_status: z.string().nullable().optional(), // ex. INACTIVE, ACTIVE
    pet_deceased_date: z.string().nullable().optional(), // ex. 2025-02-28
    pharmacy: z.string().nullable().optional(),
    phone_number: z.string().nullable(),
    practice_uuid: z.string(),
    product: z.string().nullable(),
    product_category: z.string().nullable().optional(),
    quantity: z.string().nullable(),
    reason_to_hide: z.string().nullable().optional(),
    received_at: z.string(), // ex: "2024-07-11 17:19:55.785000+00:00"
    rejected_pdf_url: z.string().nullable().optional(),
    rejection_reasons: z.array(z.string()).nullable().optional(),
    rx_uuid: z.string(),
    status: z.nativeEnum(PRESCRIPTION_STATUS),
    proactive_eligible: z.boolean(),
});

export type Prescription = z.TypeOf<typeof PrescriptionSchema>;

// Expected response for listing prescriptions so we can support pagination
export const PrescriptionsResponseSchema = z.object({
    filtered_total: z.number(),
    results: z.array(PrescriptionSchema),
});

export type PrescriptionsResponse = z.TypeOf<typeof PrescriptionsResponseSchema>;

// Prescriptions come formatted differently for the Smart Inbox
export const PrescriptionsForInboxSchema = z.object({
    prescriptions: z.object({
        approve: z.array(PrescriptionSchema),
        other: z.array(PrescriptionSchema),
        reject: z.array(PrescriptionSchema),
        unknown_patient: z.array(PrescriptionSchema),
    }),
});

export type PrescriptionsForInboxResponse = z.TypeOf<typeof PrescriptionsForInboxSchema>;

// Smart Inbox sections
export enum SMART_INBOX_SECTIONS {
    APPROVE = 'Likely approve',
    REJECT = 'Likely reject',
    OTHER = 'Need further review',
    UNKNOWN_PATIENT = 'Unknown patients',
}

// Various actions that can be taken on a prescription
export enum PRESCRIPTION_ACTION {
    // Doctor decisions
    APPROVE = 'APPROVE',
    REJECT = 'REJECT',
    APPROVE_VETSOURCE = 'APPROVE_VETSOURCE',
    REJECT_VETSOURCE = 'REJECT_VETSOURCE',

    // Send back to external pharmacy immediately
    SENDBACK = 'SENDBACK',

    // Add client to DNC list
    ADD_CLIENT_TO_DNC = 'ADD_CLIENT_TO_DNC',

    // Reassign prescription to another client
    REASSIGN_CLIENT = 'REASSIGN_CLIENT',

    // Reset prescription to pending
    RESET = 'RESET',

    // Override client decision from Converted to Not Converted
    UNCONVERT = 'UNCONVERT',

    // Refax document back to third party pharmacy
    REFAX = 'REFAX',

    // Remove duplicate status
    REMOVE_DUPLICATE = 'REMOVE_DUPLICATE',

    // Remove client from call list
    REMOVE_CLIENT_FROM_CALL_LIST = 'REMOVE_CLIENT_FROM_CALL_LIST',
}

// Options for the compound instructions select
export const COMPOUND_REASONS_OPTIONS = [
    'The pet will not take the approved product due to its flavor',
    "The pet's dosage cannot be achieved with the approved product",
    'No approved products are marketed',
    'The pet is unable to safely swallow the approved product due to the size of the dosage form',
    'The pet requires a different route of administration than is available with the approved product',
    'The approved product is temporarily unavailable due to a shortage',
    'Custom',
];

// Rejection reasons
export enum REJECTION_REASON {
    EXAM_NEEDED = 'EXAM_NEEDED',
    HEARTWORM_TEST_NEEDED = 'HEARTWORM_TEST_NEEDED',
    BLOODWORK_NEEDED = 'BLOODWORK_NEEDED',
    WRONG_DOSAGE = 'WRONG_DOSAGE',
    MOVED_PRACTICES = 'MOVED_PRACTICES',
    NOT_A_PATIENT = 'NOT_A_PATIENT',
    DUPLICATE = 'DUPLICATE',
    PET_PASSED_AWAY = 'PET_PASSED_AWAY',
    OTHER = 'OTHER',
    REMOVED_FROM_CALL_LIST = 'REMOVED_FROM_CALL_LIST',
}

export const REJECTION_REASONS: { value: REJECTION_REASON; label: string }[] = [
    {
        value: REJECTION_REASON.EXAM_NEEDED,
        label: 'Exam needed',
    },
    {
        value: REJECTION_REASON.HEARTWORM_TEST_NEEDED,
        label: 'Heartworm test needed',
    },
    {
        value: REJECTION_REASON.BLOODWORK_NEEDED,
        label: 'Bloodwork needed',
    },
    {
        value: REJECTION_REASON.WRONG_DOSAGE,
        label: 'Wrong dosage',
    },
    {
        value: REJECTION_REASON.MOVED_PRACTICES,
        label: 'Moved practices',
    },
    {
        value: REJECTION_REASON.NOT_A_PATIENT,
        label: 'Not a patient',
    },
    {
        value: REJECTION_REASON.DUPLICATE,
        label: 'Duplicate',
    },
    {
        value: REJECTION_REASON.PET_PASSED_AWAY,
        label: 'Pet passed away',
    },
    {
        value: REJECTION_REASON.OTHER,
        label: 'Other',
    },
    {
        value: REJECTION_REASON.REMOVED_FROM_CALL_LIST,
        label: 'Removed from call list',
    },
];

export enum COMMON_PHARMACY_NAME {
    CHEWY = 'Chewy',
    PETCO = 'Petco',
    PET_MEDS = 'PetMeds',
    OTHER = 'Other',
}

export const COMMON_PHARMACY_NAMES: { value: COMMON_PHARMACY_NAME; label: string }[] = [
    {
        value: COMMON_PHARMACY_NAME.CHEWY,
        label: 'Chewy',
    },
    {
        value: COMMON_PHARMACY_NAME.PETCO,
        label: 'Petco',
    },
    {
        value: COMMON_PHARMACY_NAME.PET_MEDS,
        label: 'PetMeds',
    },
    {
        value: COMMON_PHARMACY_NAME.OTHER,
        label: 'Other',
    },
];

// Reasons for removing a client from the call list
export enum CALL_LIST_REMOVAL_REASON {
    DUPLICATE_SCRIPT = 'DUPLICATE_SCRIPT',
    CLINIC_REACHING_OUT = 'CLINIC_REACHING_OUT',
    CLINIC_INSTRUCTED_NOT_TO_CALL = 'CLINIC_INSTRUCTED_NOT_TO_CALL',
    PET_PASSED_AWAY = 'PET_PASSED_AWAY',
    CALL_PARAMETERS_MET = 'CALL_PARAMETERS_MET',
    COMPOUNDED_SCRIPT = 'COMPOUNDED_SCRIPT',
    CONTROLLED_DRUG = 'CONTROLLED_DRUG',
    MISMATCHED_CLIENT_PATIENT = 'MISMATCHED_CLIENT_PATIENT',
    OTHER = 'OTHER',
}

export const CALL_LIST_REMOVAL_REASONS: { value: CALL_LIST_REMOVAL_REASON; label: string }[] = [
    {
        value: CALL_LIST_REMOVAL_REASON.DUPLICATE_SCRIPT,
        label: 'Duplicate script',
    },
    {
        value: CALL_LIST_REMOVAL_REASON.CLINIC_REACHING_OUT,
        label: 'Clinic reaching out',
    },
    {
        value: CALL_LIST_REMOVAL_REASON.CLINIC_INSTRUCTED_NOT_TO_CALL,
        label: 'Clinic instructed not to call',
    },
    {
        value: CALL_LIST_REMOVAL_REASON.PET_PASSED_AWAY,
        label: 'Pet passed away',
    },
    {
        value: CALL_LIST_REMOVAL_REASON.CALL_PARAMETERS_MET,
        label: 'Call parameters already met',
    },
    {
        value: CALL_LIST_REMOVAL_REASON.COMPOUNDED_SCRIPT,
        label: 'Compounded script',
    },
    {
        value: CALL_LIST_REMOVAL_REASON.CONTROLLED_DRUG,
        label: 'Controlled drug',
    },
    {
        value: CALL_LIST_REMOVAL_REASON.MISMATCHED_CLIENT_PATIENT,
        label: 'Mismatched client and patient',
    },
    {
        value: CALL_LIST_REMOVAL_REASON.OTHER,
        label: 'Other',
    },
];

const NoteSchema = z.object({
    id: z.string(),
    message: z.string(),
    created_at: z.string(), // ex: "2024-07-17 20:35:58.289000+00:00"
    created_by: z.string(),
});

export const NotesResponseSchema = z.object({
    notes: z.array(NoteSchema),
    rx_info: z.object({
        client_name: z.string().nullable(),
        patient_name: z.string().nullable(),
        product: z.string().nullable(),
    }),
});

export type Note = z.TypeOf<typeof NoteSchema>;
export type NotesResponse = z.TypeOf<typeof NotesResponseSchema>;

export const GeneratePrescriptionResponseSchema = z.object({
    download_url: z.string(),
});

// Match status enum to labels and colors we want to actually display to the user
export const STATUS_MAP: Record<
    PRESCRIPTION_OR_DOCTOR_STATUS | PRESCRIPTION_STATUS | DOCTOR_STATUS,
    { label: string; color: MantineColor }
> = {
    [PRESCRIPTION_OR_DOCTOR_STATUS.APPROVED]: {
        label: 'Approved',
        color: 'green',
    },
    [PRESCRIPTION_OR_DOCTOR_STATUS.AWAITING_CLIENT]: {
        label: 'Awaiting client',
        color: 'gray',
    },
    [PRESCRIPTION_OR_DOCTOR_STATUS.CANCELLED]: {
        label: 'Cancelled',
        color: 'gray',
    },
    [PRESCRIPTION_OR_DOCTOR_STATUS.CONVERTED]: {
        label: 'Converted',
        color: 'green',
    },
    [PRESCRIPTION_OR_DOCTOR_STATUS.NO_ACTION_NEEDED]: {
        label: 'No action needed',
        color: 'gray',
    },
    [PRESCRIPTION_OR_DOCTOR_STATUS.NOT_CONVERTED]: {
        label: 'Not converted',
        color: 'red',
    },
    [PRESCRIPTION_OR_DOCTOR_STATUS.PENDING]: {
        label: 'Pending',
        color: 'yellow',
    },
    [PRESCRIPTION_OR_DOCTOR_STATUS.REJECTED]: {
        label: 'Rejected',
        color: 'red',
    },
    [PRESCRIPTION_OR_DOCTOR_STATUS.TIMED_OUT]: {
        label: 'Timed out',
        color: 'gray',
    },
    [PRESCRIPTION_OR_DOCTOR_STATUS.UNKNOWN_PATIENT]: {
        label: 'Unknown patient',
        color: 'orange',
    },

    // Hidden statuses
    [PRESCRIPTION_OR_DOCTOR_STATUS.DISCARDED]: {
        label: 'Discarded',
        color: 'gray',
    },
    [PRESCRIPTION_OR_DOCTOR_STATUS.DUPLICATE]: {
        label: 'Duplicate',
        color: 'gray',
    },
    [PRESCRIPTION_OR_DOCTOR_STATUS.PREPARING]: {
        label: 'Preparing',
        color: 'gray',
    },
    [PRESCRIPTION_OR_DOCTOR_STATUS.VETSOURCE]: {
        label: 'Vetsource',
        color: '#78bb43',
    },
};
