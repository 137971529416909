import axios, { AxiosInstance } from 'axios';

// Cache of Axios instances by access token
// We only expect one access token per user at a time, but this will keep us from attempting to make any calls with a stale token
const rxAxiosClientCache: Record<string, AxiosInstance> = {};

/**
 * Returns an Axios client configured for RX Dashboard API endpoints
 *
 * Clients are cached by access token to avoid creating new instances
 * unnecessarily while ensuring each token gets its own client
 */
export function getRxAxiosClient(accessToken: string): AxiosInstance {
    // Return cached instance if it exists for this token
    if (rxAxiosClientCache[accessToken]) {
        return rxAxiosClientCache[accessToken];
    }

    // Create a new instance for this token
    const client = axios.create({
        baseURL: `https://${process.env.NEXT_PUBLIC_AMPLIFY_ID}.execute-api.us-east-2.amazonaws.com/api/rx-processing/dashboard/`,
        timeout: 30000,
        headers: { AUTHORIZATION: `Bearer ${accessToken}` },
    });

    // Remove other cached client(s)
    for (const token in rxAxiosClientCache) {
        if (token !== accessToken) {
            delete rxAxiosClientCache[token];
        }
    }

    // Cache the new instance
    rxAxiosClientCache[accessToken] = client;

    return client;
}
