import { getPrescriptionDetails } from '@/api/rx/get/getPrescriptionDetails';
import { useAuth0 } from '@auth0/auth0-react';
import { QueryClient, useQuery } from '@tanstack/react-query';

export function usePrescriptionDetailsQuery(prescriptionUuid: string) {
    const { getAccessTokenSilently } = useAuth0();

    const { isPending, isError, data, error, refetch, isRefetching } = useQuery({
        queryKey: ['prescriptions', { prescriptionUuid }],
        queryFn: async () => {
            if (prescriptionUuid) {
                const accessToken = await getAccessTokenSilently();

                return getPrescriptionDetails(accessToken, prescriptionUuid);
            }

            return null;
        },
        staleTime: 1000 * 3 * 60, // 3 minutes
    });

    return {
        isPrescriptionDetailsPending: isPending,
        isPrescriptionDetailsError: isError,
        prescriptionDetails: data,
        prescriptionDetailsError: error,
        refetchPrescriptionDetails: refetch,
        isPrescriptionDetailsRefetching: isRefetching,
    };
}

interface GetPrescriptionDetailsLazyParams {
    accessToken: string;
    prescriptionUuid: string;
    queryClient: QueryClient;
}

export async function getPrescriptionDetailsLazy({
    accessToken,
    prescriptionUuid,
    queryClient,
}: GetPrescriptionDetailsLazyParams) {
    // Fetch the Rx details
    const prescriptionDetails = await getPrescriptionDetails(accessToken, prescriptionUuid);

    // Update the query cache with the new Rx details
    queryClient.setQueryData(['prescriptions', { prescriptionUuid }], prescriptionDetails);

    return prescriptionDetails;
}
