import { PrescriptionDetails, PrescriptionDetailsSchema } from '@/types/rx/prescriptionDetails';

import { getRxAxiosClient } from '../axiosClient';

interface GetPrescriptionDetailsParams {
    rx_uuid: string;
}

export async function getPrescriptionDetails(
    accessToken: string,
    prescriptionUuid: string,
): Promise<PrescriptionDetails> {
    const axiosClient = getRxAxiosClient(accessToken);

    const params: GetPrescriptionDetailsParams = {
        rx_uuid: prescriptionUuid,
    };

    // Fetch the prescriptions details
    const { data } = await axiosClient.get('v1/prescription-details', {
        params,
    });

    // Make sure the data is in the expected format
    const prescriptionDetails: PrescriptionDetails = PrescriptionDetailsSchema.parse(data);

    return prescriptionDetails;
}
