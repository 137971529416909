import { MantineColor } from '@mantine/core';
import { z } from 'zod';

import { CompoundReasonsSchema, DOCUMENT_SOURCE } from './prescriptions';
import {
    DOCTOR_STATUS,
    InstructionsSchema,
    LabSchema,
    PRESCRIPTION_STATUS,
    SMART_INBOX_SECTIONS,
} from './prescriptions';

const AddressSchema = z.object({
    city: z.string().nullable(),
    postal_code: z.string().nullable(),
    state: z.string().nullable(),
    street: z.string().nullable(),
});

export type Address = z.TypeOf<typeof AddressSchema>;

const InvoiceItemSchema = z.object({
    date: z.string().nullable(), // ex: "07/02/2024"
    is_bloodwork: z.boolean(),
    is_exam: z.boolean(),
    is_heartworm: z.boolean(),
    product: z.string().nullable(),
    product_key: z.string(),
    employee: z.string().nullable(),
});

export type InvoiceItem = z.TypeOf<typeof InvoiceItemSchema>;

export enum ReviewRecommendation {
    APPROVE = 'APPROVE',
    REJECT = 'REJECT',
    OTHER = 'OTHER',
    UNKNOWN_PATIENT = 'UNKNOWN_PATIENT',
}

export enum Actor {
    SYSTEM = 'SYSTEM',
    DOCTOR = 'DOCTOR',
    CLIENT = 'CLIENT',
}

export enum DeliveryContext {
    CLIENT_ACCEPTED_OFFER = 'CLIENT_ACCEPTED_OFFER',
    CLIENT_DECLINED_OFFER = 'CLIENT_DECLINED_OFFER',
    CLIENT_OPTED_OUT = 'CLIENT_OPTED_OUT',
    CONVERSION_SKIPPED = 'CONVERSION_SKIPPED',
    CONVERSION_ATTEMPTS_DISABLED = 'CONVERSION_ATTEMPTS_DISABLED',
    DISCARDED = 'DISCARDED',
    DOCTOR_APPROVED = 'DOCTOR_APPROVED',
    DOCTOR_REJECTED = 'DOCTOR_REJECTED',
    DUPLICATE = 'DUPLICATE',
    NO_ADDITIONAL_CONTEXT = 'NO_ADDITIONAL_CONTEXT',
    PREFERENCES_PREVENT_APPROVALS = 'PREFERENCES_PREVENT_APPROVALS',
    PRESCRIPTION_CANCELLED = 'PRESCRIPTION_CANCELLED',
    REFAX_REQUESTED = 'REFAX_REQUESTED',
    TIMED_OUT = 'TIMED_OUT',
}

export enum DocumentType {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export enum EventType {
    APPROVED = 'APPROVED',
    CANCELLED = 'CANCELLED',
    CLIENT_RESPONSE_RECORDED = 'CLIENT_RESPONSE_RECORDED',
    DELIVERED = 'DELIVERED',
    NOT_DELIVERING = 'NOT_DELIVERING',
    OUTBOUND_CALL = 'OUTBOUND_CALL',
    OUTBOUND_TEXT = 'OUTBOUND_TEXT',
    RECEIVED = 'RECEIVED',
    REJECTED = 'REJECTED',
    RESET = 'RESET',
    TIMED_OUT = 'TIMED_OUT',
    WRITEBACK_COMPLETED = 'WRITEBACK_COMPLETED',
    WRITEBACK_IN_PROGRESS = 'WRITEBACK_IN_PROGRESS',
    WRITEBACK_PENDING = 'WRITEBACK_PENDING',
    REMOVED_FROM_CALL_LIST = 'REMOVED_FROM_CALL_LIST',
}

export const ApprovedEventSchema = z.object({
    instructions: z.string(),
    refills: z.string(),
    expiration: z.string().nullable(),
    is_compound: z.boolean(),
    compound_instructions: z.string().nullable(),
    agent_notes: z.string(),
    // TODO: remove `.optional()` when confident that backend is ready
    pharmacy_notes: z.string().nullable().optional(),
    doctor_name: z.string().nullable().optional(),
    prepared_by: z.string().nullable().optional(),
});

export const CallEventSchema = z.object({
    disposition: z.string(),
    talk_time: z.number().int(),
    to_number: z.string().optional(),
    from_number: z.string().optional(),
});

export const CancelledEventSchema = z.object({
    reason: z.string(),
});

export const ClientResponseEventSchema = z.object({
    accepted_offer: z.boolean(),
    reason: z.string().nullable(),
});

export const RemovedFromCallListEventSchema = z.object({
    reason: z.string().nullable(),
});

export const DeliveredEventSchema = z.object({
    document_type: z.nativeEnum(DocumentType).optional(),
    delivery_context: z.nativeEnum(DeliveryContext).optional(),
});

export const NotDeliveringEventSchema = z.object({
    context: z.nativeEnum(DeliveryContext).optional(),
});

export const RejectEventSchema = z.object({
    reason: z.string(),
    details: z.string().optional(),
    doctor_name: z.string().nullable().optional(),
});

export const ResetEventSchema = z.object({
    reason: z.string(),
});

export const TextMessageEventSchema = z.object({
    message: z.string(),
    to_number: z.string().optional(),
    from_number: z.string().optional(),
});

const EventHistoryListItemSchema = z.object({
    occurred_at: z.string(), // ex: "2024-07-11 14:07:11.995000+00:00"
    actor: z.nativeEnum(Actor),
    event_type: z.nativeEnum(EventType),
    // Technically a union of the different event schemas listed above, but however zod does matching can potentially cause edge cases
    // We'll still use Zod to parse this payload in the timeline component, but here we're telling Zod to not parse anything at query response time
    data: z.unknown().optional(),
});

export type EventHistoryListItem = z.TypeOf<typeof EventHistoryListItemSchema>;

const ProactiveInfoSchema = z.object({
    expected_first_contact: z.string(), // ex: "2024-07-11 17:19:55.785000+00:00"
    next_expected_refill: z.string(), // ex: "2024-07-11 17:19:55.785000+00:00"
    originating_rx_uuid: z.string(),
});

export const PrescriptionDetailsSchema = z.object({
    approval_data: z
        .object({
            // More fields here but these are the only ones we're using for now
            signature_printed_name: z.string(),
            signed_at: z.string(), // ex: "2024-07-11 15:57:03.732000+00:00"
            refills: z.string(),
        })
        .nullable(),
    approved_pdf_link: z.string().nullable().optional(),
    client_address: AddressSchema.nullable().optional(),
    client_id: z.string().nullable(),
    client_name: z.string().nullable(),
    client_email: z.string().nullable(),
    client_phone: z.string().nullable(),
    client_accepted_offer: z.boolean().nullable(),
    completed_document_link: z.string().nullable(),
    // TODO: remove `.optional()` when confident that backend is ready
    compound_reasons: CompoundReasonsSchema.nullable().optional(),
    doctor_status: z.nativeEnum(DOCTOR_STATUS),
    document_link: z.string(),
    // TODO: remove `.optional()` when confident that backend is ready
    document_source: z.nativeEnum(DOCUMENT_SOURCE).optional(),
    duplicate_of: z.string().nullable(),
    event_history: z.array(EventHistoryListItemSchema),
    instructions: InstructionsSchema,
    invoice_items: z.array(InvoiceItemSchema),
    is_compound: z.boolean(),
    last_bloodwork_lab: LabSchema.nullable(),
    last_exam: LabSchema.nullable(),
    last_heartworm_lab: LabSchema.nullable(),
    note_count: z.number(),
    patient_id: z.string().nullable(),
    patient_key: z.string().nullable(),
    pet_age: z.number().nullable(),
    pet_birthdate: z.string().nullable().optional(), // ex: 2019/04/19
    pet_breed: z.string().nullable(),
    pet_name: z.string().nullable(),
    pet_sex: z.string().nullable(),
    pet_species: z.string().nullable(),
    pet_weight: z.number().nullable(),
    pet_weight_unit: z.string().nullable(),
    pet_status: z.string().nullable().optional(), // ex. INACTIVE, ACTIVE
    pet_deceased_date: z.string().nullable().optional(), // ex. 2025-02-28
    pharmacy: z.string().nullable(),
    practice_uuid: z.string(),
    product: z.string().nullable(),
    product_category: z.string().nullable(),
    quantity: z.string().nullable(),
    reason_to_hide: z.string().nullable(),
    received_at: z.string(),
    rejected_pdf_link: z.string().nullable().optional(),
    // TODO: remove `.optional()` when confident that backend is ready
    rejection_reasons: z.array(z.string()).nullable().optional(),
    review_recommendation: z.nativeEnum(ReviewRecommendation),
    status: z.nativeEnum(PRESCRIPTION_STATUS),
    variant: z.string(),
    proactive_eligible: z.boolean(),
    proactive_info: ProactiveInfoSchema.nullable(),
});

export type PrescriptionDetails = z.TypeOf<typeof PrescriptionDetailsSchema>;

// Map review recommendation to a human-readable string
// Reuse smart inbox sections to ensure consistency
export const REVIEW_RECOMMENDATION_MAP: Record<
    ReviewRecommendation,
    {
        text: SMART_INBOX_SECTIONS;
        color: MantineColor;
    }
> = {
    [ReviewRecommendation.APPROVE]: {
        text: SMART_INBOX_SECTIONS.APPROVE,
        color: 'green.2',
    },
    [ReviewRecommendation.REJECT]: {
        text: SMART_INBOX_SECTIONS.REJECT,
        color: 'red.2',
    },
    [ReviewRecommendation.OTHER]: {
        text: SMART_INBOX_SECTIONS.OTHER,
        color: 'gray.3',
    },
    [ReviewRecommendation.UNKNOWN_PATIENT]: {
        text: SMART_INBOX_SECTIONS.UNKNOWN_PATIENT,
        color: 'yellow.2',
    },
};

const ClientSearchResultSchema = z.object({
    client_name: z.string(),
    client_key: z.string(),
    patient_key: z.string(),
    patient_name: z.string(),
    email: z.string().nullable(),
    phone: z.string().nullable(),
    address: z.string().nullable(),
});

export const ClientSearchResponseSchema = z.array(ClientSearchResultSchema);

export type ClientSearchResponse = z.TypeOf<typeof ClientSearchResponseSchema>;
export type ClientSearchResult = z.TypeOf<typeof ClientSearchResultSchema>;
