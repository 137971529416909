import { getPrescriptionDetailsLazy } from '@/hooks/rx/queries/usePrescriptionDetailsQuery';
import { useQueryParams } from '@/hooks/shared/useQueryParams';
import { useSidenavOpenState } from '@/hooks/shared/useSidenavOpenState';
import { CustomUser } from '@/types/shared/user';
import { useAuth0 } from '@auth0/auth0-react';
import { Kbd, Stack, Text } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { Spotlight, SpotlightActionData, SpotlightActionGroupData } from '@mantine/spotlight';
import { IconClipboard, IconHelp, IconPrescription, IconSearch } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { ReactNode, useState } from 'react';

interface KeyboardShortcutProviderProps {
    children: ReactNode;
}

export default function KeyboardShortcutProvider({ children }: KeyboardShortcutProviderProps) {
    const { getAccessTokenSilently, user } = useAuth0<CustomUser>();
    const queryClient = useQueryClient();
    const router = useRouter();

    // For keyboard shortcuts
    const { practiceUuid, prescriptionUuid, practiceId, corpGroupId } = useQueryParams();
    const [_, setDesktopOpened] = useSidenavOpenState();

    // For Spotlight functionality
    const [spotlightQuery, setSpotlightQuery] = useState<string>('');

    // Define which keyboard shortcuts to support, and their handlers
    useHotkeys([
        ['mod+alt+H', handleHelp],
        ['mod+alt+R', handleCopyRxUuid],
        ['mod+alt+P', handleCopyPracticeUuid],
        ['mod+alt+E', handleCopyEntityId],
        ['mod+alt+A', handleCopyAccessToken],
        ['mod+B', handleToggleDesktopSidenav],
    ]);

    // Show a toast explaining the available keyboard shortcuts
    function handleHelp() {
        notifications.show({
            title: (
                <Text size="sm" pt="md" fw={500}>
                    Available keyboard shortcuts
                </Text>
            ),
            message: (
                <Stack gap={0} my="md">
                    <Text size="sm">
                        <Kbd>mod</Kbd> + <Kbd>K</Kbd> - Open the spotlight search bar
                    </Text>
                    <Text size="sm">
                        <Kbd>mod</Kbd> + <Kbd>alt</Kbd> + <Kbd>H</Kbd> - Show this help dialog
                    </Text>
                    <Text size="sm">
                        <Kbd>mod</Kbd> + <Kbd>alt</Kbd> + <Kbd>R</Kbd> - Copy Rx UUID to clipboard
                    </Text>
                    <Text size="sm">
                        <Kbd>mod</Kbd> + <Kbd>alt</Kbd> + <Kbd>P</Kbd> - Copy practice UUID to
                        clipboard
                    </Text>
                    <Text size="sm">
                        <Kbd>mod</Kbd> + <Kbd>alt</Kbd> + <Kbd>E</Kbd> - Copy entity ID to clipboard
                    </Text>
                    <Text size="sm">
                        <Kbd>mod</Kbd> + <Kbd>alt</Kbd> + <Kbd>A</Kbd> - Copy access token to
                        clipboard
                    </Text>
                    <Text size="sm">
                        <Kbd>mod</Kbd> + <Kbd>B</Kbd> - Toggle desktop left nav
                    </Text>
                </Stack>
            ),
            withCloseButton: true,
            autoClose: false,
            color: 'gray',
            withBorder: true,
        });
    }

    // Copy Rx UUID from URL to clipboard
    async function handleCopyRxUuid() {
        if (!prescriptionUuid) {
            showWarning('Failed to copy Rx UUID - no Rx UUID found in URL');
            return;
        }

        try {
            await navigator.clipboard.writeText(prescriptionUuid);

            showSuccessMessage('Copied Rx UUID to clipboard');
        } catch (e) {
            showWarning(`Failed to copy Rx UUID to clipboard: ${e}`);
        }
    }

    // Copy practice UUID from URL to clipboard
    async function handleCopyPracticeUuid() {
        if (!practiceUuid) {
            showWarning('Failed to copy practice UUID - no practice UUID found in URL');
            return;
        }

        try {
            await navigator.clipboard.writeText(practiceUuid);

            showSuccessMessage('Copied practice UUID to clipboard');
        } catch (e) {
            showWarning(`Failed to copy practice UUID to clipboard: ${e}`);
        }
    }

    // Copy entity ID from URL to clipboard (either practice or corporate group)
    async function handleCopyEntityId() {
        if (!practiceId && !corpGroupId) {
            showWarning('Failed to copy entity ID - no entity ID found in URL');
            return;
        }

        if (practiceId) {
            try {
                await navigator.clipboard.writeText(practiceId);

                showSuccessMessage('Copied practice entity ID to clipboard');
            } catch (e) {
                showWarning(`Failed to copy practice entity ID to clipboard: ${e}`);
            }
        } else if (corpGroupId) {
            try {
                await navigator.clipboard.writeText(corpGroupId);

                showSuccessMessage('Copied corporate group entity ID to clipboard');
            } catch (e) {
                showWarning(`Failed to copy corporate group entity ID to clipboard: ${e}`);
            }
        }
    }

    // Copy access token from URL to clipboard
    async function handleCopyAccessToken() {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
            showWarning('Failed to copy access token, please refresh and try again');
            return;
        }

        try {
            await navigator.clipboard.writeText(accessToken);

            showSuccessMessage('Copied access token to clipboard');
        } catch (e) {
            showWarning(`Failed to copy access token to clipboard: ${e}`);
        }
    }

    function handleToggleDesktopSidenav() {
        setDesktopOpened((prev) => !prev);
    }

    // Show a yellow warning toast
    function showWarning(message: string) {
        notifications.show({
            message,
            autoClose: 5000,
            withCloseButton: true,
            color: 'yellow',
            withBorder: true,
        });
    }

    // Show a green success toast
    function showSuccessMessage(message: string) {
        notifications.show({
            message,
            autoClose: 5000,
            withCloseButton: true,
            color: 'green',
            withBorder: true,
        });
    }

    // Get practice UUID and redirect user to appropriate page
    async function handleRxDetailsShortcut() {
        if (!spotlightQuery || !spotlightQuery.includes('rx_')) {
            showWarning('Failed to open Rx details - no Rx UUID provided');
            return;
        }

        try {
            const accessToken = await getAccessTokenSilently();

            const prescriptionDetails = await getPrescriptionDetailsLazy({
                accessToken,
                prescriptionUuid: spotlightQuery,
                queryClient,
            });

            // Redirect user to Rx details page
            router.push(`/rx/${prescriptionDetails.practice_uuid}/prescriptions/${spotlightQuery}`);
        } catch (e) {
            showWarning(`Error fetching Rx details: ${e}`);
        }
    }

    // Define possible Spotlight actions (employees only)
    const actions: (SpotlightActionGroupData | SpotlightActionData)[] = [
        {
            group: 'Navigation',
            actions: [
                {
                    id: 'rx-details-shortcut',
                    label: 'Rx Details',
                    title: 'Rx Details',
                    keywords: ['rx_', spotlightQuery.includes('rx_') ? spotlightQuery : ''],
                    description: 'Paste rx_uuid to open Rx details page',
                    onClick: handleRxDetailsShortcut,
                    leftSection: <IconPrescription size={20} stroke={1.5} />,
                },
            ],
        },
        {
            group: 'Copy to clipboard',
            actions: [
                {
                    id: 'copy-rx-uuid',
                    label: 'Copy Rx UUID',
                    title: 'Copy Rx UUID',
                    keywords: ['copy', 'rx', 'uuid', 'rx_uuid'],
                    description: 'Copy Rx UUID to clipboard',
                    onClick: handleCopyRxUuid,
                    leftSection: <IconClipboard size={20} stroke={1.5} />,
                },
                {
                    id: 'copy-practice-uuid',
                    label: 'Copy Practice UUID',
                    title: 'Copy Practice UUID',
                    keywords: ['copy', 'practice', 'uuid', 'practice_uuid'],
                    description: 'Copy Practice UUID to clipboard',
                    onClick: handleCopyPracticeUuid,
                    leftSection: <IconClipboard size={20} stroke={1.5} />,
                },
                {
                    id: 'copy-entity-id',
                    label: 'Copy Entity ID',
                    title: 'Copy Entity ID',
                    keywords: ['copy', 'entity', 'id', 'entity_id'],
                    description: 'Copy entity ID to clipboard',
                    onClick: handleCopyEntityId,
                    leftSection: <IconClipboard size={20} stroke={1.5} />,
                },
                {
                    id: 'copy-access-token',
                    label: 'Copy Access Token',
                    title: 'Copy Access Token',
                    keywords: ['copy', 'access', 'token', 'access_token'],
                    description: 'Copy access token to clipboard',
                    onClick: handleCopyAccessToken,
                    leftSection: <IconClipboard size={20} stroke={1.5} />,
                },
            ],
        },
        {
            group: 'Help',
            actions: [
                {
                    id: 'help',
                    label: 'Help',
                    title: 'Help',
                    keywords: ['help', 'keyboard shortcuts'],
                    description: 'Show the help dialog (available keyboard shortcuts)',
                    onClick: handleHelp,
                    leftSection: <IconHelp size={20} stroke={1.5} />,
                },
            ],
        },
    ];

    return (
        <>
            {children}

            {user?.is_employee && (
                <Spotlight
                    query={spotlightQuery}
                    onQueryChange={setSpotlightQuery}
                    actions={actions}
                    nothingFound="Nothing found..."
                    searchProps={{
                        leftSection: <IconSearch size={20} stroke={1.5} />,
                        placeholder: 'Search...',
                    }}
                />
            )}
        </>
    );
}
